.menu-mobile {
   li {
      border-top: 1px solid #EEEEEE;
      position: relative;

      a {
         display: block;
         padding: 15px 0;
         font-size: 18px;
         color: #000000;
      }

      ul {
         display: none;

         li {
            a {
               display: block;
               padding: 7.5px 0;
               font-size: 14px;
               border: none;
            }
         }
      }
   }

   &__dropdown {
      position: absolute;
      top: 17px;
      right: 0;
      transition: transform 0.5s ease-out;

      &.is-open {
         transform: rotate(-90deg);
      }

      svg {
         stroke: $blue;
         width: 15px;
         height: 10px;
      }
   }
}