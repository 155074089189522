.header-social {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 6%;
   @include breakpoint(md) {
      width: auto;
      height: auto;
   }
   @include breakpoint(xl) {
      position: unset;
   }


   &__item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: #EEEEEE;
      margin: 0 7.5px;
      transition: background-color 0.5s ease-out;
      @include breakpoint(md) {
         width: 30px;
         height: 30px;
      }

      svg {
         width: 30px;
         height: 30px;
         fill: #349AB1;
         transition: fill 0.5s ease-out;
         @include breakpoint(md) {
            width: 17px;
            height: 15px;
         }
      }

      &:hover {
         background-color: #349AB1;

         & svg {
            fill: #ffffff;
         }
      }
   }
}