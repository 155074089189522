.menu-popup {
   position: fixed;
   top: 0;
   left: -310px;
   bottom: 0;
   width: 310px;
   background-color: #ffffff;
   z-index: $zIndexMenuPopup;
   padding: 35px 25px 30px;
   transition: left 0.5s ease-out;

   &.is-open {
      left: 0;
   }

   &__top {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
   }

   &__close {
      width: 21px;
      height: 21px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
         width: 25px;
         height: 3px;
         background-color: $blue;
         position: absolute;
         top: auto;
         left: 0;
         right: 0;

         &:first-child {
            transform: rotate(45deg);
         }

         &:last-child {
            transform: rotate(-45deg);
         }
      }
   }

   &__menu {
      height: 88%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 10px;
   }
}