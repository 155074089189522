.header-burger {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   width: 25px;
   height: 20px;
   position: absolute;
   top: 35px;
   left: 17px;
   @include breakpoint(md) {
      position: relative;
      top: unset;
      left: unset;
   }

   span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: $blue;
   }
}