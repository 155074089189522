.menu {
   background-color: $blue;
   position: relative;
   z-index: 250;

   &__menu > li > a {
      height: 61px;
      display: inline-flex;
      align-items: center;
   }

   &__parent {
      background-image: url("../images/menu_parent.png");
      background-repeat: no-repeat;
      background-position: center right;
      padding-right: 25px;
   }

   ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
         color: #ffffff;
      }

      li {
         position: relative;

         &:hover ul {
            display: flex;
         }

         ul {
            display: none;
            position: absolute;
            left: 0;
            flex-direction: column;
            align-items: flex-start;
            background-color: $blue;
            padding: 15px 20px;
            min-width: 150px;

            li {
               padding: 7.5px 0;
            }
         }
      }
   }
}