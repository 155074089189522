.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;
   position: relative;
   padding: 21px 0 30px;
   @include breakpoint(md) {
      flex-direction: row;
      padding: 20px 0 25px;
   }
   @include breakpoint(xl) {
      padding: 32px 0 36px;
   }

   &__logo {
      max-width: 195px;
      @include breakpoint(md) {
         max-width: 223px;
      }
      @include breakpoint(xl) {
         max-width: 265px;
      }

      img {
         max-width: 100%;
      }
   }

   &__phone {
      color: #000000;
      font-size: 19px;
      margin-bottom: 17px;
      display: block;
   }

   &__email {
      display: block;
      color: #000000;
      font-size: 14px;

      svg {
         fill: #349AB1;
         width: 16px;
         height: 10px;
      }
   }

   &__btns {
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
      width: 100%;
      @include breakpoint(md) {
         width: auto;
         margin-top: 0;
      }
   }

   &__btn {
      position: relative;
      cursor: pointer;
      background-color: #ffffff;
      border: 1px solid #349AB1;
      border-radius: 30px;
      padding: 10px 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
      text-transform: initial;
      flex-grow: 0;
      text-decoration: none;
      @include breakpoint(md) {
         padding: 10px 30px;
      }

      &:hover {
         border: 1px solid #349AB1;
      }

      &_bg, &_bg:hover {
         background: #349AB1;
         color: #ffffff;
      }

      &:first-child {
         padding: 10px 14px 10px 34px;
         @include breakpoint(md) {
            margin-right: 30px;
            padding: 10px 30px;
         }
      }

      svg {
         position: absolute;
         left: 15px;
         top: auto;
         fill: #349AB1;
         width: 11px;
         height: 14px;
         @include breakpoint(md) {
            margin-right: 10px;
         }
      }
   }
}