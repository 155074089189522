.header-language {
   cursor: pointer;
   display: inline-flex;
   align-items: center;
   margin-left: 15px;
   color: #000000;

   img {
      margin-right: 8px;
   }
}